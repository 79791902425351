import React from "react";
import { Box, Typography } from "@mui/material";

const TermsAndConditions = () => {
  return (
    <Box
      sx={{
        padding: "20px",
        maxWidth: "800px",
        margin: "0 auto",
        textAlign: "left",
        lineHeight: "1.8",
        color: "#333",
      }}
    >
      {/* Heading */}
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{
          fontWeight: "bold",
          color: "#0050B7",
          textAlign: "center",
          marginBottom: "30px",
        }}
      >
        Terms and Conditions
      </Typography>

      {/* Introduction */}
      <Typography variant="body1" paragraph>
        Welcome to Nemah Workshop. By accessing or using our services, you agree to comply with and be bound by the following terms and conditions. Please read them carefully.
      </Typography>

      {/* Section 1 */}
      <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "20px" }}>
        1. Acceptance of Terms
      </Typography>
      <Typography variant="body1" paragraph>
        By using our website or services, you agree to these terms and conditions in full. If you disagree with any part of these terms, please do not use our services.
      </Typography>

      {/* Section 2 */}
      <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "20px" }}>
        2. Service Policy
      </Typography>
      <Typography variant="body1" paragraph>
        Nemah Workshop provides repair and maintenance services for hand pallet jacks. All repairs are subject to our warranty policy. The customer is responsible for providing accurate details about their equipment.
      </Typography>

      {/* Section 3 */}
      <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "20px" }}>
        3. Payment Terms
      </Typography>
      <Typography variant="body1" paragraph>
        Payment for services must be made in full upon completion unless otherwise agreed. We accept cash, bank transfers, and other methods specified at the time of service.
      </Typography>

      {/* Section 4 */}
      <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "20px" }}>
        4. Warranty
      </Typography>
      <Typography variant="body1" paragraph>
        All repairs come with a 1-month warranty covering parts and labor. The warranty does not cover misuse, wear and tear, or unauthorized modifications.
      </Typography>

      {/* Section 5 */}
      <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "20px" }}>
        5. Limitation of Liability
      </Typography>
      <Typography variant="body1" paragraph>
        Nemah Workshop is not responsible for any indirect, incidental, or consequential damages arising from the use of our services or products.
      </Typography>

      {/* Section 6 */}
      <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "20px" }}>
        6. Privacy
      </Typography>
      <Typography variant="body1" paragraph>
        We are committed to protecting your privacy. Please review our Privacy Policy for details on how we collect and use your personal information.
      </Typography>

      {/* Section 7 */}
      <Typography variant="h6" sx={{ fontWeight: "bold", marginTop: "20px" }}>
        7. Changes to Terms
      </Typography>
      <Typography variant="body1" paragraph>
        Nemah Workshop reserves the right to update or modify these terms at any time without prior notice. Continued use of our services constitutes acceptance of the revised terms.
      </Typography>

      {/* Closing Statement */}
      <Typography
        variant="body1"
        paragraph
        sx={{ fontStyle: "italic", marginTop: "30px" }}
      >
        If you have any questions about these terms, please contact us at 0501322856.
      </Typography>
    </Box>
  );
};

export default TermsAndConditions;
