import { useRoutes } from "react-router-dom";
import AppLayout from "./layout/AppLayout";
import { HomePage } from "./pages";
import ReturnPolicyPage from "./pages/ReturnPolicy";
import { PrivacyPolicy } from "./sections";
import WarrentyPage from "./pages/WarrentyPage";
import ServicesPage from "./sections/ServiceSection";
import AboutUsPage from "./pages/AboutUsPage";
import ContactUsPage from "./pages/ContactUsPage";
import TestimonialsPage from "./pages/TestimonialPage";
import GalleryPage from "./pages/GalleryPage";
import TermsAndConsitionsPage from "./pages/TermsAndConditionsPage";
import InstallationServicesPage from "./pages/InstallationPage";

export default function Router() {
  const routes = useRoutes([
    {
      path: "/",
      element: <AppLayout />,
      children: [
        {
          path: "/",
          element: <HomePage />,
        },
        {
          path: "/home",
          element: <HomePage />,
        },
        {
          path: "/return-policy",
          element: <ReturnPolicyPage />,
        },
        {
          path: "/privacy-policy",
          element: <PrivacyPolicy />,
        },
        {
          path: "/warrenty",
          element: <WarrentyPage />,
        },
        {
          path: "/warrenty",
          element: <WarrentyPage />,
        },
        {
          path: "/services",
          element: <ServicesPage />,
        },
        {
          path: "/about-us",
          element: <AboutUsPage />,
        },
        {
          path: "/contact-us",
          element: <ContactUsPage />,
        },
        {
          path: "/testimonials",
          element: <TestimonialsPage />,
        },
        {
          path: "/gallery",
          element: <GalleryPage />,
        },
        {
          path: "/terms-and-conditions",
          element: <TermsAndConsitionsPage />,
        },
        {
          path: "/installation",
          element: <InstallationServicesPage />,
        },
      ],
    },
  ]);
  return routes;
}
