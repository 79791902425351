import React from "react";
import { Box, Typography, Grid, Card, CardContent, Avatar } from "@mui/material";
import { test2, test3 } from "../images";

const Testimonials = () => {
  const testimonials = [
    {
      name: "Ahmed Al-Farsi",
      message:
        "The service at Nemah Workshop is outstanding! My pallet jack works like new again. Highly recommend their quick and reliable repairs.",
      avatar: test3, // Replace with actual image URLs
    },
    {
      name: "Ali Al-Mansoori",
      message:
        "Great customer service and professional team. They helped me replace worn-out parts quickly. Very satisfied!",
      avatar: test2, // Replace with actual image URLs
    },
    {
      name: "Mohammed Bin Saeed",
      message:
        "Their troubleshooting and maintenance service is top-notch. My equipment has never been in better shape. Thank you!",
      avatar: test3, // Replace with actual image URLs
    },
  ];

  return (
    <Box
      sx={{
        padding: "20px",
        maxWidth: "1200px",
        margin: "0 auto",
        textAlign: "center",
      }}
    >
      {/* Heading */}
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{
          fontWeight: "bold",
          color: "#0050B7",
          marginBottom: "30px",
        }}
      >
        What Our Customers Say
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: "#7D7973",
          lineHeight: "1.8",
          marginBottom: "40px",
        }}
      >
        We take pride in delivering the best services to our customers. Here's what some of them have to say about their experience with Nemah Workshop.
      </Typography>

      {/* Testimonials Grid */}
      <Grid container spacing={4}>
        {testimonials.map((testimonial, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: "10px",
                padding: "20px",
                height:'300px'
              }}
            >
              <CardContent
                sx={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Avatar
                  src={testimonial.avatar}
                  alt={testimonial.name}
                  sx={{
                    width: 80,
                    height: 80,
                    marginBottom: "10px",
                  }}
                />
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  sx={{ color: "#0050B7" }}
                >
                  {testimonial.name}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#7D7973",
                    fontStyle: "italic",
                  }}
                >
                  "{testimonial.message}"
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Testimonials;
