import React from 'react';
import WarrantyInformation from '../sections/WarrentyInfo';
import { Helmet } from 'react-helmet';

const WarrentyPage = () => {
    return (
      <div>
        <Helmet meta="utf-8">ضمان</Helmet>
        <WarrantyInformation />
      </div>
    );
};

export default WarrentyPage;