import React from "react";
import { Box, Typography, Grid, Card, CardContent, CardMedia } from "@mui/material";
import { forklift, handles, hydrolicpump, lubricants, misc, quality, replacement, troubleshooting, wheels } from "../images";
import { electricskater } from "./gallery";

const services = [
  {
    title: "Troubleshooting & Diagnosis",
    description: "Identify issues and provide reliable solutions.",
    src: troubleshooting,
  },
  {
    title: "Replacement of Parts",
    description: "Replace worn or damaged parts with quality components.",
    src: replacement,
  },
  {
    title: "Lubrication & Maintenance",
    description: "Ensure smooth operation with proper maintenance.",
    src: lubricants,
  },
  {
    title: "Testing & Quality Control",
    description: "Thoroughly test repaired equipment for reliability.",
    src: quality,
  },
];

const sparePartsImages = [
  { src: hydrolicpump, alt: "Hydrolic Pumps" },
  { src: misc, alt: "Miscleanious" },
  { src: forklift, alt: "Fork Lift" },
  { src: electricskater, alt: "Electric Skate lifter" },
  { src: wheels, alt: "Wheels and Bearings" },
  { src: handles, alt: "Handle controllers" },
];

const ServicesPage = () => {
  return (
    <Box sx={{ padding: "20px", maxWidth: "1200px", margin: "0 auto" }}>
      {/* Services Section */}
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          color: "#0050B7",
          marginBottom: "30px",
        }}
      >
        Our Services
      </Typography>

      <Grid container spacing={4}>
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: "10px",
                padding: "20px",
                transition: "transform 0.3s",
                "&:hover": { transform: "scale(1.05)" },
              }}
            >
              <CardMedia
                component="img"
                image={service.src}
                alt={service.title}
                sx={{
                  width: "100%",
                  height: "200px",
                  objectFit: "contain",
                  borderRadius: "5px",
                  marginBottom: "15px",
                }}
              />

              <CardContent>
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  sx={{ color: "#0050B7", marginBottom: "10px" }}
                >
                  {service.title}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "#7D7973", lineHeight: "1.6" }}
                >
                  {service.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Typography
        variant={{ xs: "h6", sm: "h5", md: "h4", lg: "h3" }} // Responsive variant
        component="h2"
        gutterBottom
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          color: "green",
          marginTop: { xs: "30px", sm: "40px", md: "50px" }, // Responsive margin
          marginBottom: { xs: "20px", sm: "25px", md: "30px" },
          border: "1px solid blue",
          borderRadius: "20px",
          animation: "fadeInOut 1.5s ease-in-out infinite",
          "@keyframes fadeInOut": {
            "0%": { opacity: 0 },
            "50%": { opacity: 1 },
            "100%": { opacity: 0 },
          },
          padding: { xs: "10px", sm: "15px", md: "20px" }, // Responsive padding
        }}
      >
        NEW HAND PALLET JACKS ARE ALSO AVAILABLE AT OUR WORKSHOP
      </Typography>

      {/* Spare Parts Gallery */}
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          color: "#0050B7",
          marginTop: "50px",
          marginBottom: "30px",
        }}
      >
        Spare Parts Gallery
      </Typography>

      <Grid container spacing={2}>
        {sparePartsImages.map((image, index) => (
          <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
            <Card
              sx={{
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: "10px",
                overflow: "hidden",
                textAlign: "center",
              }}
            >
              <CardMedia
                component="img"
                image={image.src}
                alt={image.alt}
                sx={{
                  width: "100%",
                  height: "200px",
                  objectFit: "contain",
                  borderRadius: "5px",
                }}
              />

              <CardContent>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#7D7973",
                    textAlign: "center",
                  }}
                >
                  {image.alt}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ textAlign: "center", marginTop: "40px" }}>
        <Typography variant="h6" fontWeight="bold">
          Need Assistance?
        </Typography>
        <Typography variant="body1">
          Contact us today to learn more about our services or spare parts!
        </Typography>
        <Typography
          variant="body1"
          fontWeight="bold"
          sx={{ marginTop: "10px" }}
        >
          Phone: 0501322856
        </Typography>
      </Box>
    </Box>
  );
};

export default ServicesPage;
