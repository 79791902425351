import React from "react";
import { Box, Typography, Grid, Card, CardContent, CardMedia } from "@mui/material";

const InstallationServices = () => {
  const services = [
    {
      title: "Comprehensive Installation",
      description:
        "We provide end-to-end installation services to ensure your equipment is set up efficiently and safely.",
      image: "placeholder1.jpg",
    },
    {
      title: "On-Site Assembly",
      description:
        "Our technicians will assemble your hand pallet jacks or other equipment directly at your location.",
      image: "placeholder2.jpg",
    },
    {
      title: "Customization Options",
      description:
        "We offer customization during installation to meet specific operational needs.",
      image: "placeholder3.jpg",
    },
    {
      title: "Safety Checks",
      description:
        "All installations include rigorous safety checks to ensure compliance with industry standards.",
      image: "placeholder4.jpg",
    },
  ];

  return (
    <Box
      sx={{
        padding: "20px",
        maxWidth: "1200px",
        margin: "0 auto",
        textAlign: "center",
      }}
    >
      {/* Heading */}
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{
          fontWeight: "bold",
          color: "#0050B7",
          marginBottom: "30px",
        }}
      >
        Installation Services
      </Typography>

      {/* Introduction */}
      <Typography
        variant="body1"
        paragraph
        sx={{ marginBottom: "30px", color: "#555" }}
      >
        At Nemah Workshop, we specialize in professional installation services to help you get the most out of your equipment. Our expert technicians ensure that every installation is done with precision, safety, and care.
      </Typography>

      {/* Services */}
      <Grid container spacing={4}>
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                boxShadow: 3,
                borderRadius: "10px",
              }}
            >
              {/* Image Placeholder */}
              <CardMedia
                component="img"
                height="180"
                image={service.image} // Replace with actual image paths
                alt={service.title}
                sx={{
                  backgroundColor: "#f0f0f0",
                  objectFit: "cover",
                }}
              />

              {/* Content */}
              <CardContent>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ fontWeight: "bold", color: "#0050B7" }}
                >
                  {service.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {service.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default InstallationServices;
