import React from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const PrivacyPolicy = () => {
  return (
    <Box sx={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          color: "#0050B7",
          marginBottom: "20px",
        }}
      >
        Privacy Policy
      </Typography>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="personal-information-content"
          id="personal-information-header"
        >
          <Typography variant="h6" fontWeight="bold">
            Collection of Personal Information
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" gutterBottom>
            At Nemah Workshop, we collect personal information such as your
            name, phone number, email address, and any details you provide when
            contacting us or scheduling a service. This information is collected
            to improve our services and communicate effectively with you.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="use-of-information-content"
          id="use-of-information-header"
        >
          <Typography variant="h6" fontWeight="bold">
            Use of Information
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" gutterBottom>
            The information we collect is used to:
          </Typography>
          <ul>
            <li>Provide and improve our services.</li>
            <li>Respond to your inquiries and requests.</li>
            <li>Process service orders and schedule appointments.</li>
            <li>Send important updates or promotional offers.</li>
          </ul>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="data-security-content"
          id="data-security-header"
        >
          <Typography variant="h6" fontWeight="bold">
            Data Security
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" gutterBottom>
            We take reasonable steps to protect your personal information from
            unauthorized access, use, or disclosure. However, no online platform
            is 100% secure, and we encourage you to share information carefully.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="third-parties-content"
          id="third-parties-header"
        >
          <Typography variant="h6" fontWeight="bold">
            Sharing with Third Parties
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" gutterBottom>
            We do not sell, rent, or share your personal information with third
            parties for marketing purposes. However, we may share your data with
            trusted service providers to facilitate our services.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="policy-updates-content"
          id="policy-updates-header"
        >
          <Typography variant="h6" fontWeight="bold">
            Updates to This Policy
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" gutterBottom>
            This Privacy Policy may be updated periodically to reflect changes
            in our practices. We encourage you to review it regularly.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Box sx={{ marginTop: "20px", textAlign: "center" }}>
        <Typography variant="h6" fontWeight="bold">
          Contact Us
        </Typography>
        <Typography variant="body1">
          Phone: <strong>0501322856</strong>
        </Typography>
        <Typography variant="body1">
          Email: ghazanfarsidhu123@gmail.com
        </Typography>
        <Typography variant="body1">
          Visit Us: ورشة نعمه لصيانة الرافعات
        </Typography>
      </Box>
    </Box>
  );
};

export default PrivacyPolicy;
