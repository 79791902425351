import React from "react";
import { Box, Typography, Button, Grid, Card, CardContent, TextField, CardMedia } from "@mui/material";
import { landingPage, lubricants, quality, replacement, troubleshooting } from "../images";

const HomeSection = () => {
  const services = [
    {
      title: "Troubleshooting & Diagnosis",
      description: "Identify issues and provide reliable solutions.",
      src: troubleshooting,
    },
    {
      title: "Replacement of Parts",
      description: "Replace worn or damaged parts with quality components.",
      src: replacement,
    },
    {
      title: "Lubrication & Maintenance",
      description: "Ensure smooth operation with proper maintenance.",
      src: lubricants,
    },
    {
      title: "Testing & Quality Control",
      description: "Thoroughly test repaired equipment for reliability.",
      src: quality,
    },
  ];

  return (
    <Box sx={{ fontFamily: "Arial, sans-serif" }}>
      <Box
        sx={{
          background: `url(${landingPage}) center/cover no-repeat`,
          color: "white",
          textAlign: "center",
          borderRadius: "20px",
          padding: "100px 20px",
        }}
      >
        <Typography variant="h2" fontWeight="bold" gutterBottom>
          Repairing Your Hand Pallet Jacks with Expertise and Speed!
        </Typography>
        <Typography variant="h6" gutterBottom>
          Quick turnaround times, attention to detail, and customer satisfaction
          guaranteed.
        </Typography>
        <Box sx={{ mt: 3 }}>
          <Button
            variant="contained"
            size="large"
            sx={{
              background: "#0050B7",
              color: "white",
              marginRight: "10px",
              "&:hover": { background: "#003A8F" },
            }}
          >
            Learn More
          </Button>
          <Button
            variant="outlined"
            size="large"
            sx={{
              color: "white",
              borderColor: "#0050B7",
              "&:hover": { borderColor: "#003A8F" },
            }}
          >
            Request a Quote
          </Button>
        </Box>
      </Box>

      <Box sx={{ py: 5, px: 3, backgroundColor: "#F9F9F9" }}>
        <Typography
          variant="h4"
          fontWeight="bold"
          textAlign="center"
          gutterBottom
        >
          Our Services
        </Typography>
        <Grid container spacing={3} justifyContent="center">
          {services.map((service, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card
                sx={{
                  textAlign: "center",
                  padding: 2,
                  height: 250,
                  boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
                  transition: "0.3s",
                  "&:hover": { transform: "scale(1.05)" },
                }}
              >
                <CardMedia
                  component="img"
                  height="140"
                  image={service.src}
                  alt="green iguana"
                />
                <CardContent>
                  <Typography variant="h6" fontWeight="bold">
                    {service.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {service.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Why Choose Us Section */}
      <Box sx={{ py: 5, px: 3, textAlign: "center" }}>
        <Typography variant="h4" fontWeight="bold" gutterBottom>
          Why Choose Nemah Workshop?
        </Typography>
        <Typography variant="h6" color="text.secondary" gutterBottom>
          Your trusted partner for reliable hand pallet jack repairs.
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 3,
            gap: 3,
            flexWrap: "wrap",
          }}
        >
          {[
            "Expert Technicians",
            "1-Month Warranty",
            "Quick Service",
            "Affordable Pricing",
          ].map((item, index) => (
            <Box
              key={index}
              sx={{
                padding: 3,
                border: "1px solid #E0E0E0",
                borderRadius: "8px",
                width: "250px",
                textAlign: "center",
              }}
            >
              <Typography variant="h6" fontWeight="bold">
                {item}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>

      {/* Contact Section */}
      <Box
        sx={{
          py: 5,
          px: 3,
          backgroundColor: "gray",
          color: "white",
          textAlign: "center",
        }}
      >
        <Typography variant="h4" fontWeight="bold" gutterBottom>
          Get In Touch
        </Typography>
        <Typography variant="body1" gutterBottom>
          Call us at <strong>0501322856</strong> or fill out the form below.
        </Typography>
        <Box
          component="form"
          sx={{
            maxWidth: "600px",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            gap: 2,
            mt: 3,
          }}
        >
          <TextField label="Name" variant="outlined" fullWidth required />
          <TextField label="Email" variant="outlined" fullWidth required />
          <TextField
            label="Message"
            variant="outlined"
            multiline
            rows={4}
            fullWidth
            required
          />
          <Button
            variant="contained"
            sx={{ background: "white", color: "#0050B7" }}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default HomeSection;
