import React from 'react';
import { HomeSection } from '../sections';

const HomePage = () => {
    return (
      <div>
        <HomeSection />
      </div>
    );
};

export default HomePage;