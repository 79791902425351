import React from "react";
import { Box, Typography, Grid, Card, CardMedia } from "@mui/material";
import { electriclifter, electricskater, G1, G2, G3, G4, G5, G6, G7, G8, G9, handpallettruck3ton } from "./gallery";

const Gallery = () => {
  const images = [
    G1,
    G2,
    G3,
    G4,
    G5,
    G6,
    G7,
    G8,
    G9,
    electriclifter,
    electricskater,
    handpallettruck3ton
  ];

  return (
    <Box
      sx={{
        padding: "20px",
        maxWidth: "1200px",
        margin: "0 auto",
        textAlign: "center",
      }}
    >
      {/* Heading */}
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{
          fontWeight: "bold",
          color: "#0050B7",
          marginBottom: "30px",
        }}
      >
        Our Gallery
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: "#7D7973",
          lineHeight: "1.8",
          marginBottom: "40px",
        }}
      >
        Take a look at our work! From repaired hand pallet jacks to our new colour facility and spare parts, explore our latest projects and services.
      </Typography>

      {/* Gallery Grid */}
      <Grid container spacing={4}>
        {images.map((image, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: "10px",
              }}
            >
              <CardMedia
                component="img"
                image={image}
                alt={`Gallery image ${index + 1}`}
                sx={{
                  height: 200,
                  borderRadius: "10px",
                }}
              />
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Gallery;
