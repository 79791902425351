import React from 'react';
import { Helmet } from 'react-helmet';
import ContactUs from '../sections/ContactUs';

const ContactUsPage = () => {
  return (
    <div>
      <Helmet>Contact Us</Helmet>
      <ContactUs />
    </div>
  );
};

export default ContactUsPage;