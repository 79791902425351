import React from 'react';
import Gallery from '../sections/GallerySection';

const GalleryPage = () => {
    return (
      <div>
        <Gallery />
      </div>
    );
};

export default GalleryPage;