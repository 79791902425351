import React from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ReturnPolicy = () => {
  return (
    <Box sx={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          color: "#0050B7",
          marginBottom: "20px",
        }}
      >
        Return Policy
      </Typography>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="returns-content"
          id="returns-header"
        >
          <Typography variant="h6" fontWeight="bold">
            Returns and Refunds for Spare Parts
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" gutterBottom>
            <strong>Eligibility for Returns:</strong> Returns are accepted
            within <strong>7 days</strong> of the purchase date. Items must be
            unused, in their original packaging, and accompanied by the receipt
            or proof of purchase.
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Non-Returnable Items:</strong> Custom-ordered parts or items
            specifically tailored for your equipment, and parts damaged due to
            improper handling or installation.
          </Typography>
          <Typography variant="body1">
            <strong>Refund Process:</strong> Approved refunds will be processed
            within <strong>5-7 business days</strong> and issued to the original
            payment method.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="service-warranty-content"
          id="service-warranty-header"
        >
          <Typography variant="h6" fontWeight="bold">
            Service Warranty for Repairs
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" gutterBottom>
            <strong>Warranty Period:</strong> All hand pallet jack repairs come
            with a <strong>1-month warranty</strong> from the date of service.
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Warranty Coverage:</strong> Covers defects in workmanship or
            faulty replacement parts used during the repair.
          </Typography>
          <Typography variant="body1">
            <strong>Warranty Exclusions:</strong> Damage resulting from misuse,
            accidents, or unauthorized modifications, as well as normal wear and
            tear or deterioration over time.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="exchange-content"
          id="exchange-header"
        >
          <Typography variant="h6" fontWeight="bold">
            Exchange Policy
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" gutterBottom>
            Exchanges are available for spare parts if the part is defective
            upon delivery or an incorrect item was supplied. Notify us within{" "}
            <strong>48 hours</strong> of receiving the item.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Box sx={{ marginTop: "20px", textAlign: "center" }}>
        <Typography variant="h6" fontWeight="bold">
          Contact Us
        </Typography>
        <Typography variant="body1">
          Phone: <strong>0501322856</strong>
        </Typography>
        <Typography variant="body1">
          Email: ghazanfarsidhu123@gmail.com
        </Typography>
        <Typography variant="body1">
          Visit Us: ورشة نعمه لصيانة الرافعات, Madinah 42376, Saudi Arabia
        </Typography>
      </Box>
    </Box>
  );
};

export default ReturnPolicy;
