import React from "react";
import { Box, Typography, Grid, Card, CardContent, CardMedia } from "@mui/material";
import { afforableprices, experts, mission, turnaround, vision } from "../images";

const AboutUs = () => {
  return (
    <Box
      sx={{
        padding: "20px",
        maxWidth: "1200px",
        margin: "0 auto",
        textAlign: "center",
      }}
    >
      {/* About Us Heading */}
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{
          fontWeight: "bold",
          color: "#0050B7",
          marginBottom: "30px",
        }}
      >
        About Us
      </Typography>

      {/* Description Section */}
      <Typography
        variant="body1"
        sx={{
          color: "#7D7973",
          lineHeight: "1.8",
          marginBottom: "40px",
        }}
      >
        Welcome to <b>Nemah Workshop</b>, your trusted partner for hand pallet
        jack repair and maintenance. With years of experience and a dedicated
        team, we pride ourselves on providing high-quality services tailored to
        your needs. Whether you're a professional or a DIY enthusiast, our goal
        is to ensure your equipment operates smoothly and efficiently.
      </Typography>

      {/* Mission and Vision Section */}
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <Card
            sx={{
              textAlign: "center",
              padding: "20px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
            }}
          >
            <CardMedia
              component="img"
              sx={{
                width: "100%",
                height: "300px",
                objectFit: "fill",
                borderRadius: "5px",
                marginBottom: "15px",
              }}
              src={mission}
            />
            <CardContent>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{ color: "#0050B7", marginBottom: "10px" }}
              >
                Our Mission
              </Typography>
              <Typography variant="body2" sx={{ color: "#7D7973" }}>
                To deliver reliable, efficient, and affordable repair services,
                ensuring customer satisfaction and equipment longevity.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Card
            sx={{
              textAlign: "center",
              padding: "20px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
            }}
          >
            <CardMedia
              component="img"
              sx={{
                width: "100%",
                height: "300px",
                objectFit: "fill",
                borderRadius: "5px",
                marginBottom: "15px",
              }}
              src={vision}
            />
            <CardContent>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{ color: "#0050B7", marginBottom: "10px" }}
              >
                Our Vision
              </Typography>
              <Typography variant="body2" sx={{ color: "#7D7973" }}>
                To be the go-to workshop for hand pallet jack repair and spare
                parts, recognized for our expertise and commitment.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Why Choose Us Section */}
      <Typography
        variant="h4"
        component="h2"
        gutterBottom
        sx={{
          fontWeight: "bold",
          color: "#0050B7",
          marginTop: "50px",
          marginBottom: "30px",
        }}
      >
        Why Choose Us?
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          <Card
            sx={{
              textAlign: "center",
              padding: "20px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
            }}
          >
            <CardMedia
              component="img"
              sx={{
                width: "100%",
                height: "300px",
                objectFit: "fill",
                borderRadius: "5px",
                marginBottom: "15px",
              }}
              src={experts}
            />
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ color: "#0050B7" }}
            >
              Expert Team
            </Typography>
          </Card>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Card
            sx={{
              textAlign: "center",
              padding: "20px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
            }}
          >
            <CardMedia
              component="img"
              sx={{
                width: "100%",
                height: "300px",
                objectFit: "fill",
                borderRadius: "5px",
                marginBottom: "15px",
              }}
              src={afforableprices}
            />
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ color: "#0050B7" }}
            >
              Affordable Pricing
            </Typography>
          </Card>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Card
            sx={{
              textAlign: "center",
              padding: "20px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
            }}
          >
            <CardMedia
              component="img"
              sx={{
                width: "100%",
                height: "300px",
                objectFit: "fill",
                borderRadius: "5px",
                marginBottom: "15px",
              }}
              src={turnaround}
            />
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ color: "#0050B7" }}
            >
              Fast Turnaround
            </Typography>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutUs;
