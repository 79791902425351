import React from "react";
import { Helmet } from "react-helmet";
import AboutUs from "../sections/AboutUs";

export default function AboutUsPage() {
  return (
    <div>
      <Helmet>About Nemah</Helmet>
      <AboutUs />
    </div>
  );
}
