import React from 'react';
import TermsAndConditions from '../sections/TermsAndConditions';

const TermsAndConsitionsPage = () => {
    return (
      <div>
        <TermsAndConditions />
      </div>
    );
};

export default TermsAndConsitionsPage;