import React from 'react';
import InstallationServices from '../sections/InstallationService';

const InstallationServicesPage = () => {
    return (
      <div>
        <InstallationServices />
      </div>
    );
};

export default InstallationServicesPage;