import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Outlet, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import { logo } from "../images";

export default function AppLayout() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedMenu, setSelectedMenu] = React.useState("Home");
  const isMenuOpen = Boolean(anchorEl);

  const menuItems = [
    "Home",
    "Services",
    "About Us",
    "Testimonials",
    "Gallery",
    "Contact Us",
  ];

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNavigatePages = (item) => {
    setSelectedMenu(item);
    try {
      switch (item) {
        case "Home":
          navigate("/home");
          break;
        case "Services":
          navigate("/services");
          break;
        case "About Us":
          navigate("/about-us");
          break;
        case "Testimonials":
          navigate("/testimonials");
          break;
        case "Gallery":
          navigate("/gallery");
          break;
        case "Contact Us":
          navigate("/contact-us");
          break;
        default:
          break;
      }
    } catch (error) {
      navigate("/home");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        overflowX: "hidden",
      }}
    >
      <AppBar
        position="sticky"
        sx={{
          background: "#FFFFFF",
          boxShadow: "none",
          color: "black",
          borderRadius: "15px",
          height: { xs: "60px", sm: "80px" },
          margin: { xs: "10px", sm: "20px" },
          maxWidth: "calc(100% - 40px)",
        }}
      >
        <Toolbar>
          <img
            src={logo}
            alt="Nemah"
            style={{
              width: "80px",
              height: "80px",
              borderRadius: "20%",
              margin: "auto",
            }}
          />
          <Box sx={{ flexGrow: 1 }} />

          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
              flexGrow: 1,
              gap: 2,
            }}
          >
            {menuItems.map((item, index) => (
              <Typography
                key={index}
                variant="h6"
                fontWeight="bold"
                sx={{
                  cursor: "pointer",
                  fontFamily: "revert",
                  color: selectedMenu === item ? "#0050B7" : "#7D7973",
                  borderBottom:
                    selectedMenu === item ? "2px solid #0050B7" : "none",
                  "&:hover": {
                    color: "#0050B7",
                  },
                }}
                onClick={() => handleNavigatePages(item)}
              >
                {item}
              </Typography>
            ))}
          </Box>

          <IconButton
            edge="end"
            color="inherit"
            sx={{ display: { xs: "flex", md: "none" } }}
            onClick={handleMenuOpen}
          >
            <MenuIcon />
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            open={isMenuOpen}
            onClose={handleMenuClose}
            sx={{ display: { xs: "block", md: "none" } }}
          >
            {menuItems.map((item, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  handleMenuClose();
                  handleNavigatePages(item);
                }}
                sx={{
                  color: selectedMenu === item ? "#0050B7" : "#7D7973",
                  "&:hover": { color: "#0050B7" },
                }}
              >
                {item}
              </MenuItem>
            ))}
          </Menu>
        </Toolbar>
      </AppBar>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          padding: "20px",
          boxSizing: "border-box",
        }}
      >
        <Outlet />
      </Box>
      <footer
        style={{
          backgroundColor: "#252422",
          color: "white",
          textAlign: "center",
          padding: "10px",
          marginBottom: 0,
        }}
      >
        <Footer />
      </footer>
    </Box>
  );
}
