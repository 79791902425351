import React from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  Card,
} from "@mui/material";

const ContactUs = () => {
  return (
    <Box
      sx={{
        padding: "20px",
        maxWidth: "1200px",
        margin: "0 auto",
        textAlign: "center",
      }}
    >
      {/* Contact Us Heading */}
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{
          fontWeight: "bold",
          color: "#0050B7",
          marginBottom: "30px",
        }}
      >
        Contact Us
      </Typography>

      <Typography
        variant="body1"
        sx={{
          color: "#7D7973",
          lineHeight: "1.8",
          marginBottom: "40px",
        }}
      >
        Have questions or need assistance? Reach out to us! We're here to help
        with all your hand pallet jack repair needs. Fill out the form below, or
        use the provided contact information to get in touch.
      </Typography>

      {/* Contact Form and Location Section */}
      <Grid container spacing={4}>
        {/* Contact Form */}
        <Grid item xs={12} md={6}>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <TextField
              label="Your Name"
              variant="outlined"
              fullWidth
              required
            />
            <TextField
              label="Your Email"
              variant="outlined"
              fullWidth
              required
              type="email"
            />
            <TextField
              label="Your Message"
              variant="outlined"
              fullWidth
              required
              multiline
              rows={4}
            />
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#0050B7",
                color: "#FFFFFF",
                textTransform: "none",
              }}
              size="large"
            >
              Send Message
            </Button>
          </Box>
        </Grid>

        {/* Location & Contact Information */}
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              textAlign: "center",
              padding: "20px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
            }}
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3633.400656444374!2d39.51332787591164!3d24.402144663442666!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15bdc76bc1dbe68b%3A0x154e13315635ac08!2z2YjYsdi02Kkg2YbYudmF2Ycg2YTYtdmK2KfZhtipINin2YTYsdin2YHYudin2Ko!5e0!3m2!1sen!2s!4v1733983164457!5m2!1sen!2s"
              width="600"
              height="450"
              style={{ border: 0 }}
              title="Location"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ color: "#0050B7", marginBottom: "10px" }}
            >
              Visit Us
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#7D7973", marginBottom: "20px" }}
            >
              Nemah Workshop
              <br />
              ورشة نعمه لصيانة الرافعات , Madinah Saudi Arabia
              <br />
              Phone: 0501322856
              <br />
              Email: ghazanfarsidhu123@gmail.com
            </Typography>
            <Typography variant="body2" sx={{ color: "#7D7973" }}>
              Business Hours: <br />
              Sunday - Thursday: 7:30 AM - 7:30 PM
              <br />
              Friday: Closed
            </Typography>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContactUs;
