import React from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Helmet } from "react-helmet";

const WarrantyInformation = () => {
  return (
    <Box sx={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
      <Helmet meta="utf-8">ضمان</Helmet>
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          color: "#0050B7",
          marginBottom: "20px",
        }}
      >
        Warranty Information
      </Typography>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="warranty-coverage-content"
          id="warranty-coverage-header"
        >
          <Typography variant="h6" fontWeight="bold">
            Warranty Coverage
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" gutterBottom>
            All hand pallet jack repairs performed by Nemah Workshop include a{" "}
            <strong>1-month warranty</strong> from the date of service. This
            warranty covers:
          </Typography>
          <ul>
            <li>Defects in workmanship.</li>
            <li>Faulty replacement parts used during the repair.</li>
          </ul>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="warranty-exclusions-content"
          id="warranty-exclusions-header"
        >
          <Typography variant="h6" fontWeight="bold">
            Warranty Exclusions
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" gutterBottom>
            The warranty does not cover:
          </Typography>
          <ul>
            <li>Damage caused by misuse, accidents, or improper handling.</li>
            <li>Normal wear and tear or deterioration over time.</li>
            <li>
              Unauthorized modifications or repairs made by third parties.
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="claims-process-content"
          id="claims-process-header"
        >
          <Typography variant="h6" fontWeight="bold">
            Claims Process
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" gutterBottom>
            To file a warranty claim:
          </Typography>
          <ul>
            <li>
              Contact us at <strong>0501322856</strong> or via email at
              ghazanfarsidhu123@gmail.com.
            </li>
            <li>Provide proof of service (receipt or invoice).</li>
            <li>
              Describe the issue and include photos or videos, if applicable.
            </li>
          </ul>
          <Typography variant="body1" gutterBottom>
            Once your claim is reviewed, we will provide instructions for the
            next steps, which may include repair or replacement.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="additional-services-content"
          id="additional-services-header"
        >
          <Typography variant="h6" fontWeight="bold">
            Additional Services
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" gutterBottom>
            If your repair or part is not covered under warranty, we also
            provide out-of-warranty repair services at a competitive cost. Our
            team will guide you through the available options.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Box sx={{ marginTop: "20px", textAlign: "center" }}>
        <Typography variant="h6" fontWeight="bold">
          Contact Us
        </Typography>
        <Typography variant="body1">
          Phone: <strong>0501322856</strong>
        </Typography>
        <Typography variant="body1">
          Email: ghazanfarsidhu123@gmail.com
        </Typography>
        <Typography variant="body1">
          Visit Us: ورشة نعمه لصيانة الرافعات Madinah 42376, Saudi Arabia
        </Typography>
      </Box>
    </Box>
  );
};

export default WarrantyInformation;
