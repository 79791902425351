import React from 'react';
import Testimonials from '../sections/Testimonial';

const TestimonialsPage = () => {
    return (
      <div>
        <Testimonials />
      </div>
    );
};

export default TestimonialsPage;