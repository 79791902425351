import React from 'react';
import ReturnPolicy from '../sections/ReturnPolicy';

const ReturnPolicyPage = () => {
    return (
      <div>
        <ReturnPolicy />
      </div>
    );
};

export default ReturnPolicyPage;